<template>
  <svg :class="$style.icon">
    <use :xlink:href="`#icon-${name}`" />
  </svg>
</template>
<script>
export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      default: ''
    }
  }

}
</script>
<style lang="scss" module>
  .icon {
    fill: currentcolor;
  }
</style>
