import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import { useIcon } from '@/helpers/useIcon.js'
import AppIcon from '@/components/AppIcon'
import '@/assets/styles/all.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'aos/dist/aos.css'

createApp(App)
  .use(store)
  .use(router)
  .use(createMetaManager(), { refreshOnceOnNavigation: true })
  .use(useIcon)
  .component('AppIcon', AppIcon)
  .mount('#app')
