<template>
  <footer :class="$style.footer">
    <div
      class="text-white"
      :class="$style.footerContent"
    >
      <div class="container-xxxl gx-0 gx-lg-3">
        <div class="row gx-0 gx-lg-3 align-items-stretch">
          <div class="col-12 d-block d-lg-none">
            <div
              role="button"
              class="text-center py-4 border-lighter border-bottom h4 text-white"
              @click="scrollToTop"
            >
              TOP
            </div>
          </div>
          <div class="col-12 col-lg-2 flex-grow-0 d-flex flex-column justify-content-center align-items-center">
            <router-link
              to="/"
              class="d-block me-3 me-lg-0 my-3 my-lg-0 link-white"
            >
              <img
                :src="footer?.logo?.src"
                :alt="footer?.logo?.alt"
                class="mx-auto"
                :class="$style.logo"
              >
            </router-link>
            <div class="d-flex d-lg-none align-items-center mb-5">
              <a
                v-for="(item, index) in footerSocial"
                :key="index"
                :href="item.href"
                class="link-white"
                target="_blank"
              >
                <AppIcon
                  :name="`social-${item.name.toLowerCase()}`"
                  class="mx-2"
                  :class="$style.icon"
                />
              </a>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none col-12">
            <div
              role="button"
              class="d-flex align-items-center justify-content-between p-3"
              @click="setShowContact"
            >
              營業處資訊
              <AppIcon
                v-if="showContact"
                name="minus"
                class="icon-4"
              />
              <AppIcon
                v-else
                name="plus"
                class="icon-4"
              />
            </div>
            <div class="border-lighter border-bottom" />
            <transition name="collapse">
              <div
                v-if="showContact"
                class="bg-body text-white"
              >
                <p
                  v-for="(item, index) in footer.location"
                  :key="index"
                  class="p-3"
                >
                  <span v-if="item.title">
                    {{ item.title }}：<br>
                  </span>

                  <span v-if="item.address">
                    <!-- 地址： -->
                    <a
                      :href="`https://www.google.com.tw/maps/place/${item.address}`"
                      class="link-white"
                      target="_blank"
                    >{{ item.address }}</a><br>
                  </span>

                  <span v-if="item.phone">
                    電話：<a
                      :href="`tel:${item.phone}`"
                      class="link-white"
                    >{{ item.phone }}</a><br>
                  </span>
                  <span v-if="item.fax">
                    傳真：{{ item.fax }}
                  </span>
                  <span v-if="item.email">
                    EMAIL：<a
                      :href="`mailto:${item.email}`"
                      class="link-white"
                    >{{ item.email }}</a><br>
                  </span>

                  <span v-if="item.servicetime">
                    服務時間：{{ item.servicetime }}
                  </span>
                </p>
              </div>
            </transition>
          </div>
          <!-- computer -->
          <div class="d-none d-lg-flex align-items-center flex-wrap col-lg flex-grow-2">
            <div class="w-100 d-flex align-items-start flex-wrap">
              <div
                v-for="(item, index) in footer.location"
                :key="index"
                class="col"
              >
                <div
                  v-if="item.title"
                  class="d-flex align-items-center mb-2"
                >
                  <div class="fs-6 mb-0 flex-shrink-0">
                    {{ item.title }}：
                  </div>
                </div>
                <div
                  v-if="item.address"
                  class="d-flex align-items-center mb-2"
                >
                  <!-- <div class="lh-1 mb-0 flex-shrink-0">
                  地址：
                </div> -->
                  <a
                    :href="`https://www.google.com.tw/maps/place/${item.address}`"
                    class="link-white"
                    target="_blank"
                  >{{ item.address }}</a>
                </div>
                <div
                  v-if="item.phone"
                  class="d-flex align-items-center mb-2"
                >
                  <div class="lh-1 mb-0 flex-shrink-0">
                    電話：
                  </div>
                  <a
                    :href="`tel:${item.phone}`"
                    class="link-white"
                  >{{ item.phone }}</a>
                </div>
                <div
                  v-if="item.fax"
                  class="d-flex align-items-center mb-2"
                >
                  <div class="lh-1 mb-0 flex-shrink-0">
                    傳真：
                  </div>
                  <p>{{ item.fax }}</p>
                </div>
                <div
                  v-if="item.email"
                  class="d-flex align-items-center mb-2"
                >
                  <div class="lh-1 mb-0 flex-shrink-0">
                    EMAIL：
                  </div>
                  <a
                    :href="`mailto:${item.email}`"
                    class="link-white"
                  >{{ item.email }}</a>
                </div>
                <div
                  v-if="item.servicetime"
                  class="d-flex align-items-center mb-2"
                >
                  <div class="lh-1 mb-0 flex-shrink-0">
                    服務時間：
                  </div>
                  <p>{{ item.servicetime }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none col-12">
            <div
              role="button"
              class="d-flex align-items-center justify-content-between p-3"
              @click="setShowInfo"
            >
              網站資訊
              <AppIcon
                v-if="showInfo"
                name="minus"
                class="icon-4"
              />
              <AppIcon
                v-else
                name="plus"
                class="icon-4"
              />
            </div>
            <div class="border-lighter border-bottom" />
            <transition name="collapse">
              <div v-if="showInfo">
                <div class="d-flex flex-column bg-body text-white p-3">
                  <a
                    v-for="(item, index) in footer.customPage.page"
                    :key="index"
                    :href="item.href"
                    class="link-white"
                  >{{ item.name }}</a>
                </div>
              </div>
            </transition>
          </div>
          <!-- computer -->
          <div class="d-none d-lg-flex flex-column align-items-center justify-content-end flex-wrap col-lg-auto">
            <div class="d-flex align-items-center mb-5">
              <a
                v-for="(item, index) in footerSocial"
                :key="index"
                :href="item.href"
                class="link-white"
                target="_blank"
              >
                <AppIcon
                  :name="`social-${item.name.toLowerCase()}`"
                  class="mx-2"
                  :class="$style.icon"
                />
              </a>
            </div>
            <div class="d-flex align-items-center">
              <a
                v-for="(item, index) in footer.customPage.page"
                :key="index"
                :href="item.href"
                class="d-block me-3 link-white"
              >{{ item.name }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between bg-dark px-3 pt-3 pb-4">
      <small>{{ footer.copyright }}</small>
      <small>Design By
        <a
          href="https://wwooz.com"
          class="text-white"
          target="_blank"
        >沃知</a></small>
    </div>
  </footer>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed } from 'vue-demi'
export default {
  name: 'TheFooter',
  async setup () {
    const { getters } = useStore()

    const footer = computed(() => getters.getIndex.footer)

    const footerSocial = computed(() => {
      if (footer.value.social && footer.value.social.length > 0) {
        return footer.value.social.filter(item => item.href && item.href !== 'https://')
      } else {
        return []
      }
    })

    const showContact = ref(false)
    const setShowContact = () => {
      showContact.value = !showContact.value
    }

    const showInfo = ref(false)
    const setShowInfo = () => {
      showInfo.value = !showInfo.value
    }
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    return {
      scrollToTop,
      footerSocial,
      footer,
      showContact,
      setShowContact,
      showInfo,
      setShowInfo
    }
  }
}
</script>

<style lang="scss" module>
.icon {
  width: 36px;
  height: 36px;
  fill: currentcolor;

  @include media-breakpoint-up(lg) {
    width: 24px;
    height: 24px;
  }
}

.logo {
  width: 60px;
  transition: all 0.3s;

  @include media-breakpoint-up(lg) {
    width: 100px;
  }

  &:hover {
    opacity: 0.6;
  }
}

.footer {
  // upper than fixed line icon
  position: relative;
  z-index: 11;
}

.footerContent {
  position: relative;
  z-index: 1;
  font-size: 14px;
  line-height: 1;
  background: $black;

  :global(.link-white) {
    transition: all 0.3s;

    &:focus,
    &:active,
    &:hover {
      color: $primary;
    }

    @include media-breakpoint-up(lg) {

      &:focus,
      &:active,
      &:hover {
        color: $dark;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 20px 120px;
    background: $primary;
  }

  @include media-breakpoint-up(xl) {
    padding: 80px 100px 120px;
    background: $primary;
  }
}
</style>
