<template>
  <div>
    <!-- menu -->
    <transition name="menu">
      <div
        v-if="showMenu"
        class="d-flex align-items-start align-items-lg-center justify-content-center position-fixed w-100 h-100 top-0 end-0 bg-dark"
        :class="$style.zIndex42"
      >
        <!-- cross -->
        <div
          role="button"
          class="position-fixed top-0 end-0 p-1 pt-2 m-4 d-flex d-lg-none flex-column align-items-center justify-content-center bg-dark rounded-circle"
          @click="setShowMenu(false)"
        >
          <AppIcon
            name="cross"
            class="icon-7"
          />
        </div>
        <!-- menu item -->
        <ul
          class="list-unstyled d-flex flex-column"
          :class="$style.menuList"
        >
          <a
            v-for="(item, index) in navList"
            :key="index"
            role="button"
            class="pb-3 mb-1"
            @click="scrollToPosition(item.hash)"
          >
            <div class="text-primary h3">
              {{ item.title }}
            </div>
            <div class="text-white small">
              {{ item.subTitle.toUpperCase() }}
            </div>
          </a>
        </ul>
      </div>
    </transition>
    <!-- 手機板選單 -->
    <div
      class="position-fixed top-0 end-0 w-100 d-flex d-lg-none align-items-center justify-content-between bg-dark"
      :class="$style.nav"
    >
      <div class="ms-3">
        <!-- logo -->
        <router-link
          v-if="logo"
          to="/"
          :class="[$style.logoWrap, {'opacity-0': !toShowLogo}]"
        >
          <img
            :src="logo.src"
            :alt="logo.alt"
            :class="$style.logo"
          >
        </router-link>
      </div>
      <div
        class="d-flex flex-column align-items-start m-3"
        role="button"
        @click="setShowMenu()"
      >
        <AppIcon
          name="hamburger"
          class="icon-7"
        />
      </div>
    </div>
    <!-- 電腦版選單 -->
    <nav
      id="nav"
      class="position-fixed top-0 start-0 w-100 d-none d-lg-flex align-items-center justify-content-end bg-dark"
      :class="$style.nav"
    >
      <!-- menu item -->
      <div class="container-xxl">
        <div class="row">
          <div class="col-auto">
            <!-- logo -->
            <router-link
              v-if="logo"
              to="/"
              :class="[$style.logoWrap, {'opacity-0': !toShowLogo}]"
            >
              <img
                :src="logo.src"
                :alt="logo.alt"
                :class="$style.logo"
              >
            </router-link>
          </div>
          <ul
            class="col list-unstyled d-flex justify-content-end"
            :class="$style.menuList"
          >
            <a
              v-for="(item, index) in navList"
              :key="index"
              role="button"
              class="d-block py-8 ms-8 link-primary"
              @click="scrollToPosition(item.hash)"
            >
              <div
                class="h4"
                :class="$style.title"
              >
                {{ item.title }}
              </div>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
const toShowLogo = ref(false)
onMounted(() => {
  window.addEventListener('scroll', () => {
    const showLogo = document.documentElement.scrollTop > 180
    toShowLogo.value = showLogo
  })
})

const { getters } = useStore()
const logo = computed(() => getters.getLogo)

const router = useRouter()

router.beforeEach((to, from, next) => {
  setShowMenu(false)
  next()
})

const navList = ref([
  {
    title: '關於我們',
    subTitle: 'ABOUT shih-yi',
    hash: 'about'
  },
  {
    title: '服務項目',
    subTitle: 'SERVICE',
    hash: 'service'
  },
  {
    title: '工程方案',
    subTitle: 'Product',
    hash: 'product'
  },
  {
    title: '工程實績',
    subTitle: 'Project Portfolio',
    hash: 'work'
  },
  {
    title: '客戶見證',
    subTitle: 'client Testimonials',
    hash: 'client'
  },
  {
    title: '常見問題',
    subTitle: 'Customization FQA',
    hash: 'QA'
  },
  {
    title: '聯絡我們',
    subTitle: 'Contact Us',
    hash: 'reservation'
  }
])

const showMenu = ref(false)
const setShowMenu = (active) => {
  showMenu.value = typeof active === 'boolean' ? active : !showMenu.value
}

const scrollToPosition = (id) => {
  const section = document.querySelector(`#${id}`)
  window.scrollTo({
    top: section.offsetTop,
    behavior: 'smooth'
  })
  setShowMenu(false)
}

</script>

<style lang="scss" module>
:global(.menu-enter-active),
:global(.menu-leave-active) {
  transition: transform 0.3s ease;
}

:global(.menu-enter-from),
:global(.menu-leave-to) {
  transform: translate(0%, -100%);
}

.logoWrap {
  z-index: 41;
  display: block;
  width: 48px;
  height: calc($nav-height-mobile - 10px);
  transition: opacity 0.3s;

  .logo {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 63px;
    height: calc($nav-height - 10px);
  }
}

.menuList {
  margin: 100px 0 0;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.title {
  transition: all 0.3s;
}

.zIndex42 {
  z-index: 42;
}

.nav {
  z-index: 40;
  width: 100%;
  height: $nav-height-mobile;

  @include media-breakpoint-up(lg) {
    height: $nav-height;
  }
}

.menuButton {
  margin-bottom: 6rem;
  width: 40px;
  height: 56px;
}

.link {
  position: relative;
  color: $gray;
  transition: all 0.3s;

  &:hover {
    color: $primary;
  }
}

</style>
